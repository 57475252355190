@font-face {
  font-family: "A-OTF-UDShinGoNTPro-Medium";
  src: url("../resources/fonts/A-OTF-UDShinGoNTPro-Medium.otf")
    format("opentype");
}

body {
  margin: 0;
  font-family: "A-OTF-UDShinGoNTPro-Medium", sans-serif;
  font-weight: 300;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

img {
  image-rendering: -webkit-optimize-contrast;
}

::placeholder {
  font-size: 18px;
}